import styled, { css } from 'styled-components'
import { switchProp } from 'styled-tools'

import getStyle from 'happitu/src/getStyle'

export default styled.i<{ type?: 'primary' }>`
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 60px;
  width: 120px;
  display: block;
  z-index: 1;

  ${switchProp(
    'type',
    {},
    css`
      background-image: url(${getStyle('logo')});
    `,
  )}
`
