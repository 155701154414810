import styled, { css } from 'styled-components'
import { switchProp } from 'styled-tools'

import getStyle from 'happitu/src/getStyle'
import { Anchor } from 'happitu/src/helpers/tooltipHelpers'
import theme, { font } from 'theme'

export const Arrow = styled.div<{ anchor: Anchor }>`
  border: 4px solid rgb(0, 0, 0, 0);
  content: '';
  display: block;
  position: absolute;
  z-index: 1;

  ${switchProp('anchor', {
    bottom: css`
      left: 50%;
      transform: translateX(-50%);
      border-top-color: ${getStyle('tooltip-background')};
      top: 100%;
    `,
    bottomLeft: css`
      left: 12px;
      border-top-color: ${getStyle('tooltip-background')};
      top: 100%;
    `,
    bottomRight: css`
      right: 12px;
      border-top-color: ${getStyle('tooltip-background')};
      top: 100%;
    `,
    left: css`
      top: 50%;
      transform: translateY(-50%);
      border-right-color: ${getStyle('tooltip-background')};
      right: 100%;
    `,
    right: css`
      top: 50%;
      transform: translateY(-50%);
      border-left-color: ${getStyle('tooltip-background')};
      left: 100%;
    `,
    top: css`
      left: 50%;
      transform: translateX(-50%);
      border-bottom-color: ${getStyle('tooltip-background')};
      bottom: 100%;
    `,
    topLeft: css`
      left: 12px;
      border-bottom-color: ${getStyle('tooltip-background')};
      bottom: 100%;
    `,
    topRight: css`
      right: 12px;
      border-bottom-color: ${getStyle('tooltip-background')};
      bottom: 100%;
    `,
  })}
`

export const StyledTooltip = styled.div<{ anchor: Anchor }>`
  background: ${getStyle('tooltip-background')};
  border-radius: 5px;
  box-shadow: ${theme('menu.boxShadow')};
  color: ${getStyle('tooltip-color')};
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: ${font('weight', 'medium')};
  line-height: 100%;
  padding: 6px 10px;
  pointer-events: none;
  position: absolute;
  user-select: none;
  white-space: nowrap;
  z-index: 2;
  max-width: 300px;
`

export const Wrapper = styled.div<{ anchor: Anchor }>`
  position: relative;
`
