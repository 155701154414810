import { merge } from 'lodash'
import * as React from 'react'
import * as ReactDOM from 'react-dom'

import { Arrow, StyledTooltip, Wrapper } from './Tooltip.styles'

import Transition from 'happitu/src/components/Animations/Transition'
import { fadeTransition } from 'happitu/src/helpers/animations'
import {
  Anchor,
  getPosition,
  getSlideDirection,
  Position,
} from 'happitu/src/helpers/tooltipHelpers'

interface Props {
  anchor: Anchor
  children?: React.ReactNode
  tooltip: React.ReactNode
}

interface ToolTipPortal {
  anchor: Anchor
  anchorTo: React.RefObject<HTMLDivElement>
  tooltip: React.ReactNode
  isVisible: boolean
}

const portal = document.createElement('div')
portal.setAttribute('id', 'tooltip-portal')
document.body.appendChild(portal)

function TooltipPortal({ anchor, anchorTo, tooltip, isVisible }: ToolTipPortal) {
  const [position, setPosition] = React.useState<Position | null>(null)
  const tooltipRef = React.useRef<HTMLDivElement>(null)
  const arrowAnchor = position && position.arrowAnchor
  const slideTransition = arrowAnchor && getSlideDirection(arrowAnchor)
  const transition = React.useMemo(() => merge(fadeTransition(), slideTransition), [
    slideTransition,
  ])

  React.useEffect(() => {
    if (anchorTo.current) {
      const anchorMetrics = anchorTo.current.getBoundingClientRect()
      if (tooltipRef.current) {
        const windowHeight = window.innerHeight
        const windowWidth = window.innerWidth
        const tooltipMetrics = tooltipRef.current.getBoundingClientRect()

        setPosition(
          getPosition({
            anchor,
            anchorMetrics,
            tooltipMetrics,
            windowHeight,
            windowWidth,
          }),
        )
      }
    }
  }, [isVisible])

  return ReactDOM.createPortal(
    <>
      {position && transition && arrowAnchor && (
        <Transition
          isVisible={isVisible}
          style={{ ...position.tooltip, position: 'absolute' }}
          transition={transition}
        >
          <StyledTooltip anchor={anchor}>
            {tooltip}
            <Arrow anchor={arrowAnchor} />
          </StyledTooltip>
        </Transition>
      )}
      <StyledTooltip
        ref={tooltipRef}
        style={{
          opacity: 0,
          visibility: 'hidden',
          position: 'fixed',
          top: '-1000',
          left: '-1000',
        }}
        anchor={anchor}
      >
        {tooltip}
      </StyledTooltip>
    </>,
    portal,
  )
}

export default function Tooltip({ anchor, children, tooltip }: Props) {
  const wrapperRef = React.useRef<HTMLDivElement>(null)
  const [visible, setVisible] = React.useState(false)

  const tooltipPortal = (
    <TooltipPortal
      anchor={anchor}
      tooltip={tooltip}
      anchorTo={wrapperRef}
      isVisible={visible}
    />
  )

  // These are explicitly set separately to address a potential bug
  // A cursor positioned where an element is currently being rendered will not trigger a mouse enter event
  // Therefore setVisible(!visible) would leave a pesky persistent tooltip
  const toggleVisible = () => setVisible(true)
  const toggleInvisible = () => setVisible(false)

  return (
    <Wrapper
      anchor={anchor}
      ref={wrapperRef}
      onMouseEnter={toggleVisible}
      onMouseLeave={toggleInvisible}
    >
      {children}
      {wrapperRef.current && tooltipPortal}
    </Wrapper>
  )
}
