import React, { ComponentProps, useEffect, useMemo } from 'react'
import styled from 'styled-components'

import getTheme from '../getTheme'
import { getBrandName } from '../helpers/localizeHelper'

import Icon from 'happitu/src/components/Icon'
import LogoWordmark from 'happitu/src/components/LogoWordmark'
import Tooltip from 'happitu/src/components/Tooltip'
import Box from 'happitu/src/components/box'
import Spacer from 'happitu/src/components/spacer'
import Text from 'happitu/src/components/text'
import getStyle from 'happitu/src/getStyle'
import { font } from 'theme'

interface Props extends ComponentProps<'div'> {
  title: string
}

const GuestLayout = (props: Props) => {
  const year = useMemo(() => new Date().getFullYear(), [])
  const theme = getTheme()

  useEffect(() => {
    document.title = props.title
  }, [props.title])

  return (
    <div className={props.className} style={props.style}>
      <Home href={theme.currentTheme.homeUrl}>
        <Tooltip tooltip={`Return to ${getBrandName()}`} anchor="right">
          <Icon type="home" />
        </Tooltip>
      </Home>

      <section>
        <article>{props.children}</article>
      </section>
      <Box justify="center">
        <Text color="sub" align="center">
          &copy; {year} All Rights Reserved.
        </Text>
        <Spacer y="2" />
        <Box direction="ltr" gap="1" justify="center" inline>
          <a href={theme.currentTheme.termsUrl}>Terms</a>
          <a href={theme.currentTheme.privacyUrl}>Privacy</a>
        </Box>
      </Box>
    </div>
  )
}

export const VisitorHeading = styled.header`
  margin-bottom: 2em;
  text-align: center;

  h1 {
    font-size: 3em;
    font-weight: ${font('weight', 'medium')};
    line-height: 1.5;
    margin-bottom: 1em;
  }

  p {
    font-size: ${font('size', 'medium')};
    line-height: 1.33;
    margin-bottom: 1em;
  }
`

const Home = styled.a`
  position: fixed;
  top: 2em;
  left: 2em;
  font-size: 1.5em;
  color: ${getStyle('text-sub-color')};
`

export default styled(GuestLayout)`
  display: grid;
  min-height: 100%;
  padding: 4em 2em;
  grid-template-rows: 1fr auto;
  width: 100%;
  justify-content: center;
  grid-gap: 5em;

  ${LogoWordmark} {
    width: 200px;
  }

  section {
    margin: 0 auto;
    max-width: 600px;
    padding-top: 10vh;
    width: 100%;
  }

  article {
    padding: 0em;
  }
`
