import React from 'react'

import Notice from 'happitu/src/components/Fields/Notice'

export default function SentEmailNotice(props: { email: string | null }) {
  return (
    <Notice type="success">
      An email was sent to <strong>{props.email || 'someone'}</strong> with instructions.
    </Notice>
  )
}
