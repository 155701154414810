import apiRequest from './request'
import { handleReject, handleSuccess } from './responseHandler'
export { changePasswordRequest as passwordResetRequest } from 'happitu/src/services/happitu-api/usersService'

/**
 * Sends a request to the API for a password reset email.
 * @param data
 * @returns {Promise}
 */
export const requestTokenRequest = (data: { email: string | null }) =>
  new Promise((resolve, reject) =>
    apiRequest
      .post('/users/reset-password')
      .send(data)
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r))),
  )

/**
 * Verifies that the given token is valid.
 * @param token
 * @returns {Promise}
 */
export const verifyTokenRequest = (token: string) =>
  new Promise((resolve, reject) =>
    apiRequest
      .post('/users/validate_token')
      .send({ token })
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r))),
  )

export const forgotOrganizationRequest = (email: string) =>
  new Promise((resolve, reject) =>
    apiRequest
      .post('/users/forgot-organization')
      .send({ email })
      .then((r) => resolve(handleSuccess(r)))
      .catch((r) => reject(handleReject(r))),
  )
