import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { AppContainer } from 'react-hot-loader'
import { ThemeProvider } from 'styled-components'

import { getTheme } from '../helpers/localizeHelper'

import Login from 'happitu/src/modules/Login'
import BaseStyles from 'happitu/src/themes/base.styles'
import * as theme from 'happitu/src/themes/default'

const render = (Component: React.ComponentClass) => {
  ReactDOM.render(
    <AppContainer>
      <ThemeProvider theme={{ ...theme, currentTheme: getTheme() }}>
        <>
          <BaseStyles />
          <Component />
        </>
      </ThemeProvider>
    </AppContainer>,
    document.getElementById('root'),
  )
}

render(Login)
