import {
  SLIDE_DOWN,
  SLIDE_LEFT,
  SLIDE_RIGHT,
  SLIDE_UP,
} from 'happitu/src/helpers/animations'

interface Props {
  anchor: Anchor
  anchorMetrics: ClientRect
  tooltipMetrics: ClientRect
  windowHeight: number
  windowWidth: number
}

interface ArrowAnchorIndex {
  [key: string]: Anchor
}

export interface Position {
  arrowAnchor: Anchor
  tooltip: {
    left: number
    top: number
  }
}

export type Anchor =
  | 'bottom'
  | 'bottomLeft'
  | 'bottomRight'
  | 'left'
  | 'right'
  | 'top'
  | 'topLeft'
  | 'topRight'

const slideDirectionIndex = {
  bottom: SLIDE_DOWN,
  bottomLeft: SLIDE_DOWN,
  bottomRight: SLIDE_DOWN,
  left: SLIDE_LEFT,
  right: SLIDE_RIGHT,
  top: SLIDE_UP,
  topLeft: SLIDE_UP,
  topRight: SLIDE_UP,
}

const arrowAnchorIndex: ArrowAnchorIndex = {
  bottomcenter: 'bottom',
  bottomleft: 'bottomLeft',
  bottomright: 'bottomRight',
  left: 'left',
  right: 'right',
  topcenter: 'top',
  topleft: 'topLeft',
  topright: 'topRight',
}

export function getSlideDirection(arrowAnchor: Anchor) {
  return slideDirectionIndex[arrowAnchor]
}

function getArrowAnchor(arrowPos: string) {
  return arrowAnchorIndex[arrowPos]
}

export function getPosition({
  anchor,
  anchorMetrics,
  tooltipMetrics,
  windowHeight,
  windowWidth,
}: Props) {
  const buffer = 8
  const margin = 12

  const getBottom = () => {
    const defaultLeft =
      anchorMetrics.left + anchorMetrics.width / 2 - tooltipMetrics.width / 2
    const defaultTop = anchorMetrics.top + anchorMetrics.height + buffer

    let arrowPos, left, top

    if (defaultTop + tooltipMetrics.height > windowHeight - margin) {
      arrowPos = 'bottom'
      top = anchorMetrics.top - tooltipMetrics.height - buffer
    } else {
      arrowPos = 'top'
      top = defaultTop
    }

    if (defaultLeft + tooltipMetrics.width > windowWidth - margin) {
      arrowPos += 'right'
      left = windowWidth - margin - tooltipMetrics.width
    } else if (defaultLeft < margin) {
      arrowPos += 'left'
      left = margin
    } else {
      arrowPos += 'center'
      left = defaultLeft
    }

    const arrowAnchor: Anchor = getArrowAnchor(arrowPos)

    return {
      arrowAnchor,
      tooltip: {
        left,
        top,
      },
    }
  }

  const getBottomLeft = () => {
    const defaultLeft = anchorMetrics.left
    const defaultTop = anchorMetrics.top + anchorMetrics.height + buffer

    let arrowPos, left, top

    if (defaultTop + tooltipMetrics.height > windowHeight - margin) {
      arrowPos = 'bottom'
      top = anchorMetrics.top - tooltipMetrics.height - buffer
    } else {
      arrowPos = 'top'
      top = defaultTop
    }

    if (defaultLeft + tooltipMetrics.width > windowWidth - margin) {
      arrowPos += 'right'
      left = windowWidth - margin - tooltipMetrics.width
    } else {
      arrowPos += 'left'
      left = defaultLeft
    }

    const arrowAnchor: Anchor = getArrowAnchor(arrowPos)

    return {
      arrowAnchor,
      tooltip: {
        left,
        top,
      },
    }
  }

  const getBottomRight = () => {
    const defaultLeft = anchorMetrics.left + anchorMetrics.width - tooltipMetrics.width
    const defaultTop = anchorMetrics.top + anchorMetrics.height + buffer

    let arrowPos, left, top

    if (defaultTop + tooltipMetrics.height > windowHeight - margin) {
      arrowPos = 'bottom'
      top = anchorMetrics.top - tooltipMetrics.height - buffer
    } else {
      arrowPos = 'top'
      top = defaultTop
    }

    if (defaultLeft < margin) {
      arrowPos += 'left'
      left = margin
    } else if (defaultLeft + tooltipMetrics.width > windowWidth - margin) {
      arrowPos += 'right'
      left = windowWidth - tooltipMetrics.width - margin
    } else {
      arrowPos += 'right'
      left = defaultLeft
    }

    const arrowAnchor: Anchor = getArrowAnchor(arrowPos)

    return {
      arrowAnchor,
      tooltip: {
        left,
        top,
      },
    }
  }

  const getLeft = () => {
    const defaultLeft = anchorMetrics.left - tooltipMetrics.width - buffer
    const top = anchorMetrics.top + anchorMetrics.height / 2 - tooltipMetrics.height / 2

    let arrowAnchor: Anchor, left

    if (defaultLeft < margin) {
      arrowAnchor = 'left'
      left = anchorMetrics.left + anchorMetrics.width + buffer
    } else {
      arrowAnchor = 'right'
      left = defaultLeft
    }

    return {
      arrowAnchor,
      tooltip: {
        left,
        top,
      },
    }
  }

  const getRight = () => {
    const defaultLeft = anchorMetrics.left + anchorMetrics.width + buffer
    const top = anchorMetrics.top + anchorMetrics.height / 2 - tooltipMetrics.height / 2

    let arrowAnchor: Anchor, left

    if (defaultLeft + tooltipMetrics.width > windowWidth - margin) {
      arrowAnchor = 'right'
      left = anchorMetrics.left - tooltipMetrics.width - buffer
    } else {
      arrowAnchor = 'left'
      left = defaultLeft
    }

    return {
      arrowAnchor,
      tooltip: {
        left,
        top,
      },
    }
  }

  const getTop = () => {
    const defaultLeft =
      anchorMetrics.left + anchorMetrics.width / 2 - tooltipMetrics.width / 2
    const defaultTop = anchorMetrics.top - tooltipMetrics.height - buffer

    let arrowPos, left, top

    if (defaultTop < margin) {
      arrowPos = 'top'
      top = anchorMetrics.top + anchorMetrics.height + buffer
    } else {
      arrowPos = 'bottom'
      top = defaultTop
    }

    if (defaultLeft + tooltipMetrics.width > windowWidth - margin) {
      arrowPos += 'right'
      left = windowWidth - margin - tooltipMetrics.width
    } else if (defaultLeft < margin) {
      arrowPos += 'left'
      left = margin
    } else {
      arrowPos += 'center'
      left = defaultLeft
    }

    const arrowAnchor: Anchor = getArrowAnchor(arrowPos)

    return {
      arrowAnchor,
      tooltip: {
        left,
        top,
      },
    }
  }

  const getTopLeft = () => {
    const defaultLeft = anchorMetrics.left
    const defaultTop = anchorMetrics.top - tooltipMetrics.height - buffer

    let arrowPos, left, top

    if (defaultTop < margin) {
      arrowPos = 'top'
      top = anchorMetrics.top + anchorMetrics.height + buffer
    } else {
      arrowPos = 'bottom'
      top = defaultTop
    }

    if (defaultLeft + tooltipMetrics.width > windowWidth - margin) {
      arrowPos += 'right'
      left = windowWidth - margin - tooltipMetrics.width
    } else {
      arrowPos += 'left'
      left = defaultLeft
    }

    const arrowAnchor: Anchor = getArrowAnchor(arrowPos)

    return {
      arrowAnchor,
      tooltip: {
        left,
        top,
      },
    }
  }

  const getTopRight = () => {
    const defaultLeft = anchorMetrics.left + anchorMetrics.width - tooltipMetrics.width
    const defaultTop = anchorMetrics.top - tooltipMetrics.height - buffer

    let arrowPos, left, top

    if (defaultTop < margin) {
      arrowPos = 'top'
      top = anchorMetrics.top + anchorMetrics.height + buffer
    } else {
      arrowPos = 'bottom'
      top = defaultTop
    }

    if (defaultLeft < margin) {
      arrowPos += 'left'
      left = anchorMetrics.left + anchorMetrics.width + buffer
    } else if (defaultLeft + tooltipMetrics.width > windowWidth - margin) {
      arrowPos += 'right'
      left = windowWidth - tooltipMetrics.width - margin
    } else {
      arrowPos += 'right'
      left = defaultLeft
    }

    const arrowAnchor: Anchor = getArrowAnchor(arrowPos)

    return {
      arrowAnchor,
      tooltip: {
        left,
        top,
      },
    }
  }

  const anchorPositionIndex = {
    bottom: getBottom,
    bottomLeft: getBottomLeft,
    bottomRight: getBottomRight,
    left: getLeft,
    right: getRight,
    top: getTop,
    topLeft: getTopLeft,
    topRight: getTopRight,
  }

  const posProps: Position = anchorPositionIndex[anchor]()

  return posProps
}
