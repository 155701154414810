import * as cookies from 'js-cookie'
import Cookies from 'js-cookie'
import React, { Component } from 'react'
import { Form, Field } from 'react-final-form'

import errorMessages from './Login.errors'

import { SuperAgentResponse } from 'happitu/src/actions/relay'
import Autogrow from 'happitu/src/components/Animations/Autogrow'
import SentEmailNotice from 'happitu/src/components/Callouts/SentEmailNotice'
import { Notice } from 'happitu/src/components/Fields'
import Icon from 'happitu/src/components/Icon'
import LogoWordmark from 'happitu/src/components/LogoWordmark'
import Box from 'happitu/src/components/box'
import { Button } from 'happitu/src/components/button'
import { TextField } from 'happitu/src/components/final-form-fields'
import Spacer from 'happitu/src/components/spacer'
import Text from 'happitu/src/components/text'
import { ORGANIZATION_ALIAS_COOKIE } from 'happitu/src/constants/cookies'
import { emailValidator, requiredValidator } from 'happitu/src/constants/validators'
import { getOrganizationId } from 'happitu/src/helpers/authHelpers'
import { setPageTitle } from 'happitu/src/helpers/domHelper'
import { getBrandName } from 'happitu/src/helpers/localizeHelper'
import { error } from 'happitu/src/helpers/loggerHelper'
import GuestLayout from 'happitu/src/layouts/guest'
import { forgotOrganizationRequest } from 'happitu/src/services/happitu-api/passwordResetService'
import apiRequest from 'happitu/src/services/happitu-api/request'

interface LoginFormValues {
  email: string
  password?: string
  organizationId?: string | null
  organizationSlug?: string
}

enum FormView {
  Login = 0,
  ResetPassword = 1,
}

interface State {
  multiOrgUser: boolean
  sentEmail: null | string
  errorMessage: null | React.ReactNode
  failedPassword: number
  view: FormView
}

const getOrganizationAlias = (): string | null => {
  try {
    const organizationSlug = Cookies.get(ORGANIZATION_ALIAS_COOKIE)
    if (organizationSlug) return organizationSlug
  } catch (e) {
    error(
      `unable to load from local storage (cookieKey: ${ORGANIZATION_ALIAS_COOKIE})`,
      e,
    )
  }
  return null
}

interface LoginHeaderProps {
  title: string
}

const LoginHeader = (props: LoginHeaderProps) => {
  return (
    <div>
      <LogoWordmark
        style={{ width: '100%', maxWidth: 400, height: 90, margin: '0 auto' }}
      />
      <Spacer y="3" />
      <Text bold align="center" as="h2">
        {props.title}
      </Text>
      <Spacer y="2" />
    </div>
  )
}

export default class Login extends Component<{}, State> {
  state = {
    sentEmail: null,
    errorMessage: null,
    failedPassword: 0,
    multiOrgUser: false,
    view: FormView.Login,
    initialValues: { organizationId: getOrganizationId() },
  }

  componentDidMount() {
    setPageTitle('Login')
  }

  handleSubmit = ({
    email,
    organizationId,
    organizationSlug,
    password,
  }: LoginFormValues) => {
    return apiRequest
      .post('/')
      .send({
        email: email.trim(),
        organizationId: organizationId ? organizationId.trim() : undefined,
        organizationSlug: organizationSlug
          ? organizationSlug.toLowerCase().trim()
          : undefined,
        password,
      })
      .then(
        (
          response: SuperAgentResponse<{
            users: UserRecord[]
            organizations: OrganizationRecord[]
          }>,
        ) => {
          const user = response.body.users[0]
          const organization = response.body.organizations[0]
          const expiredPassword =
            user && user.mustResetPasswordBy
              ? new Date(user.mustResetPasswordBy) <= new Date()
              : false

          if (expiredPassword) {
            window.location.replace('/reset-password/expired-password')
          } else {
            if (organization.happituVersion === 'v2') {
              window.location.href = `${process.env.SERVICE_V2_APP}/org/${organization.id}`
            } else {
              const redirectTo = cookies.get('redirectTo')
              cookies.remove('redirectTo')
              window.location.replace(redirectTo || `/org/${organization.id}`)
            }
          }
        },
      )
      .catch(this.handleError)
  }

  handleError = (err: any) => {
    try {
      if (err.status === 409) {
        return this.setState({
          errorMessage: null,
          multiOrgUser: true,
        })
      }
      window.scrollTo(0, 0)
      const failedPassword = err.status === 403 ? this.state.failedPassword + 1 : 0
      this.setState({
        errorMessage: errorMessages(
          err.response.body.errors,
          {
            toggleForgottenPassword: this.toggleForgottenPassword,
            failedPassword,
          },
          err.response,
        ),
        failedPassword,
      })
    } catch (e) {
      error('unknown error while logging in:', e)
      this.setState({
        errorMessage: errorMessages([]),
      })
    }
  }

  handleForgotOrganization = (email: string) => {
    forgotOrganizationRequest(email)
      .then(() => {
        this.setState({
          sentEmail: email,
        })
      })
      .catch((err) => {
        this.setState({
          sentEmail: null,
          errorMessage: errorMessages(err),
        })
      })
  }

  handleReset = ({ email, organizationId, organizationSlug }: LoginFormValues) => {
    return apiRequest
      .post('/users/reset-password')
      .send({
        email: email.trim(),
        organizationId: organizationId ? organizationId.trim() : undefined,
        organizationSlug: organizationSlug
          ? organizationSlug.toLowerCase().trim()
          : undefined,
      })
      .then(() =>
        this.setState({
          view: FormView.Login,
          failedPassword: 0,
          sentEmail: email,
          errorMessage: null,
          multiOrgUser: false,
        }),
      )
      .catch(this.handleError)
  }

  toggleForgottenPassword = () => {
    this.setState({
      view: this.state.view === FormView.Login ? FormView.ResetPassword : FormView.Login,
      sentEmail: null,
      errorMessage: null,
    })
  }

  renderFormFields = (values: LoginFormValues) => {
    switch (this.state.view) {
      case FormView.ResetPassword:
        return (
          <>
            <LoginHeader title="Reset your password" />
            {this.state.errorMessage}
            {this.state.sentEmail && <SentEmailNotice email={this.state.sentEmail} />}
            <Field
              autoComplete="username"
              autoFocus={values.organizationId || values.organizationSlug}
              component={TextField}
              label="Email Address"
              name="email"
              type="text"
              validate={emailValidator()}
            />
          </>
        )
      default:
        return (
          <>
            <LoginHeader title={`Sign in to ${getBrandName()}`} />
            {this.state.errorMessage}
            {this.state.sentEmail && <SentEmailNotice email={this.state.sentEmail} />}
            <Field
              autoComplete="username"
              autoFocus
              component={TextField}
              label="Email Address"
              name="email"
              type="email"
              validate={emailValidator()}
            />
            <Field
              autoComplete="current-password"
              component={TextField}
              label="Password"
              name="password"
              type="password"
              validate={requiredValidator('Please enter your password')}
            />
          </>
        )
    }
  }

  render() {
    const onSubmit =
      this.state.view === FormView.ResetPassword ? this.handleReset : this.handleSubmit

    return (
      <GuestLayout title={`Log in to ${getBrandName()}`}>
        <Form
          initialValues={this.state.initialValues}
          onSubmit={onSubmit}
          render={({ handleSubmit, submitting, values }) => (
            <form onSubmit={handleSubmit} style={{ width: 400 }} method="post">
              {this.renderFormFields(values)}
              {this.state.multiOrgUser && (
                <Autogrow isVisible>
                  <Spacer y="2" />
                  <Box border="top" paddingTop="2">
                    <Notice type="info">
                      <Text>
                        It looks like you belong to multiple organizations. Please enter
                        the alias of the one you would like to sign in to.
                      </Text>
                      <Spacer y />
                      <Text>
                        Don't remember the alias?
                        <br />
                        <a onClick={() => this.handleForgotOrganization(values.email)}>
                          Find out which ones you belong to.
                        </a>
                      </Text>
                    </Notice>
                    <Field
                      autoFocus
                      component={TextField}
                      label="Organization Alias"
                      name="organizationSlug"
                      initialValue={getOrganizationAlias()}
                      type="text"
                      validate={requiredValidator(
                        'Please enter which organization you want to sign in to',
                      )}
                    />
                  </Box>
                  <Spacer y="2" />
                </Autogrow>
              )}
              <footer>
                <Button
                  loading={submitting}
                  primary
                  size="large"
                  type="submit"
                  style={{ width: '100%' }}
                  value={
                    this.state.view === FormView.ResetPassword ? 'Send Email' : 'Log In'
                  }
                />
                <Spacer y="1" />
                <Text align="center">
                  <a onClick={this.toggleForgottenPassword} style={{ cursor: 'pointer' }}>
                    {this.state.view === FormView.Login ? (
                      'Forgot Password?'
                    ) : (
                      <span style={{ marginLeft: '-1em' }}>
                        <Icon type="arrow-left" /> Go back
                      </span>
                    )}
                  </a>
                </Text>
              </footer>
            </form>
          )}
        />
      </GuestLayout>
    )
  }
}
