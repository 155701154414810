import React from 'react'

import createErrorHandler from 'happitu/src/createErrorHandler'

interface Props {
  failedPassword: number
  toggleForgottenPassword: React.MouseEventHandler
}

function incorrectPasswordMessage(props: Props) {
  return props.failedPassword >= 3 ? (
    <span>
      Looks like your having some difficulty, would you like to{' '}
      <a onClick={props.toggleForgottenPassword}>reset your password?</a>
    </span>
  ) : (
    <span>
      {`Sorry, that password isn't right. We can help you `}
      <a onClick={props.toggleForgottenPassword}>{`reset your password`}</a>.
    </span>
  )
}

function tooManyFailuresMessage(props: Props) {
  return (
    <span>
      Sorry, there have been too many login failures on your account recently. Try again
      in a little while or{' '}
      <a onClick={props.toggleForgottenPassword}>reset your password</a>.
    </span>
  )
}

export default createErrorHandler<Props>({
  'request': {
    '429': tooManyFailuresMessage,
    'incorrect-password': `Sorry, that password isn't correct.`,
  },
  'user': {
    'load': "Sorry, we couldn't find an organization with that alias",
    'not-found': "Sorry, we couldn't find an account with that email address.",
  },
  'session': {
    'incorrect-password': incorrectPasswordMessage,
    'not-found': "Sorry, we couldn't find an account with that email address.",
  },
  'auth': {
    'not-authorized': 'The token you provided has expired. Please log in and try again.',
  },
  'user-collection': {
    'not-found': `A user with this email address doesn't exist`,
  },
  'old-password-version': {
    'not-authorized': (props: Props) => (
      <span>
        It looks like your password has expired. Please use the{' '}
        <a onClick={props.toggleForgottenPassword}>forgot password</a> form to reset it.
      </span>
    ),
  },
})
